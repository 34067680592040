.App {
}

.App-logo {
  animation: App-logo-spin alternate infinite 2s ease-in-out;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
  text-align: center;
}

.App-header h2 {
  margin: 0;
}

.App-intro {
  padding: 1rem;
}

@keyframes App-logo-spin {
  from { transform: scale(1); }
  to { transform: scale(1.08); }
}
